import React from "react";
import "../offer-box.css";
import { useTranslation } from "react-i18next";

export const Comunidad = () => {

  const { t, i18n } = useTranslation();

  return (
    <>
      <img
        src={`/img/empresas/clientesPortada-${i18n.language}.png`}
        style={{ width: "100%" }}
        alt=""
      />
      <div style={{ marginBottom: 80 }}>
        <section
          className="section section-lg bg-transparent novi-background"
          data-preset='{"title":"Partners","category":"partner","reload":false,"id":"partners-5"}'
        >
          <div className="container">
            <div className="group-20 d-flex flex-wrap align-items-end justify-content-between">
              <div className="bloqueIndex">
                <h2> {t('OurClients_Direct')} </h2>
              </div>
            </div>
            <div className="row row-offset-lg row-30 row-lg-40">
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/cantel.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/cos.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/curvature.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/decisionOne.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/dellTech.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/esp.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/essintial.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/hcl.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/indra.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/ncr.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/nttData.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/octopian.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/optistar.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/outSource.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/ricoh.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/source1.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/sourceSupport.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/stefanini.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/systemsMaintenance.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas3/worldwide.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section section-lg bg-transparent novi-background"
          data-preset='{"title":"Partners","category":"partner","reload":false,"id":"partners-5"}'
        >
          <div className="container">
            <div className="group-20 d-flex flex-wrap align-items-end justify-content-between">
              <div className="bloqueIndex">
                <h2> {t('OurClients_FinalClients')} </h2>
              </div>
            </div>
            <div className="row row-offset-lg row-30 row-lg-40">
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/abbott.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/agilent.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/americanAir.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/BD.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/bombardier.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/cargill.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/centuryLink.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/copaAirlines.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/dell.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/drPepper.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/estee.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/ey.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/johnDeere.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/marsh.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/mattel.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/metso.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/nike.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/novartis.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/oxford.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/ricoh.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/sabre.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/shire.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/sita.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/spectra.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas2/united.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section section-lg bg-transparent novi-background"
          data-preset='{"title":"Partners","category":"partner","reload":false,"id":"partners-5"}'
        >
          <div className="container">
            <div className="group-20 d-flex flex-wrap align-items-end justify-content-between">
              <div className="bloqueIndex">
                <h2> {t('OurClients_Partners')} </h2>
              </div>
            </div>
            <div className="row row-offset-lg row-30 row-lg-40">
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/amulet.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/citrix.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/commvault.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/curvature.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/dell.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/dellemc.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/doubleTake.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner ">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/f5.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/hp.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/intel.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/liquidware.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/microsoft.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/nubelity.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/oracle.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/quest.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/redHat.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/sap.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/sonicWall.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/Veeam.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/veritas.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/vmWare.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="partner">
                  <img
                    className="partner-image offer-box offer-box-light"
                    src="/img/empresas1/westconComstor.png"
                    alt=""
                    width="290"
                    height="120"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
